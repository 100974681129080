body {
  background: #e5e5e5;
  font-family: Helvetica, sans-serif;
}

.error {
  background: darkred;
  color: white;
  padding: 20px;
  font-size: 18px;
}

div {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

a:link {
  color: inherit;
}
a:active {
  color: inherit;
}
a:visited {
  color: inherit;
}
a:hover {
  color: inherit;
}
